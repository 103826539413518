import React from 'react'
import { Box, Button, Container } from '@material-ui/core'
import Text from '../Text'
import { useMobile } from '../../utils/hooks'
import { navigate } from "gatsby"
import { HeaderHeightContext } from '../Layout'

const back = () => {
    navigate("/");
}

const ConfirmContent = props => {
    const mobile = useMobile()
    const headerHeight = React.useContext(HeaderHeightContext)

    return (
        <Box component={Container} zIndex={5}>
            <Box style={mobile ? { paddingTop: headerHeight } : undefined}>
                <Text className="tlt" variant="h1" color="primary" mb={mobile ? 2 : 4}>
                    Thank you!
            </Text>

                <Box maxWidth={600}>
                    <Text variant="subtitle1" color="white" mb={mobile ? 2 : 4} data-sal="fade" data-sal-duration="2000"
                        data-sal-delay="600">
                        We will contact you soon.<br />
                    Check your mail box for more information about <b>Incubit</b>.<br />
                    </Text>
                </Box>

                <Box mt={mobile ? 3 : 4}>
                    <Button variant="contained" color="primary" type={'subbmit'} onClick={back}>
                        Back
                </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ConfirmContent
